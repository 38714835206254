<script>
import useVuelidate from '@vuelidate/core'
import {required} from '@vuelidate/validators'
import {FilterMatchMode} from 'primevue/api';

/* COMPOSANTS */
import AkFormList from "@components/layout/AkFormList";
import AkInputText from "@components/input/AkInputText";
import AkDropdown from "@components/input/AkDropdown";
import AkDialog from "@components/general/AkDialog";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import utilsMixin from "@mixins/utilsMixin";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import agerService from "@services/agerService";
import uerService from "@services/uerService";
import ceiService from "@services/ceiService";
import rolePermissionService from "@services/rolePermissionService";
import AkCheckbox from "@components/input/AkCheckbox";
import mobileMixin from "@mixins/mobileMixin";

export default {
  components: {AkCheckbox, AkFormList, AkInputText, AkDropdown, AkDialog},
  mixins: [randomRef, utilsMixin, roleMixin, mobileMixin],
  metaInfo() {
    return {
      title: "cei.list",
    }
  },
  setup() {
    return {v$: useVuelidate()}
  },
  data() {
    return {
      list: [],
      current: {},
      agerList: [],
      uerList: [],
      loading: false,
      multiSortMeta: [
        {field: 'agerLabel', order: 1},
        {field: 'uerLabel', order: 1}
      ],
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
      },
    }
  },
  validations() {
    return {
      current: {
        label: {required},
        agerId: {required},
        uerId: {required},
        importKey: {required},
      }
    }
  },
  mounted() {
    this.getRef().showTotalLoader();

    let p0 = rolePermissionService.authorizeAccessByPermission(this.permissionConst.admin.view);

    let p1 = agerService.findAllCurrentForAdmin();
    p1.then(data => this.agerList = data);

    let p2 = uerService.findAllCurrent();
    p2.then(data => this.uerList = data);

    let p3 = ceiService.findAllCurrent();
    p3.then(data => this.list = data);

    Promise.all([p0, p1, p2, p3]).then(() => {
      this.loading = false;
      this.getRef().hideLoader();
    });
  },
  methods: {
    create() {
      this.getRef().resetMessages();
      this.v$.$touch();
      if (this.v$.$error) return;

      this.getRef().showTotalLoader();
      ceiService.create(this.current).then(data => {
        this.list.push(data);
        this.current = {};

        this.getRef().success(this.$t("cei.added"));
        this.$refs.dialogCreate.hide();
      }).finally(() => {
        this.getRef().hideLoader();
      });
    },
    update() {
      this.getRef().resetMessages();
      this.v$.$touch();
      if (this.v$.$error) return;

      this.getRef().showTotalLoader();
      ceiService.update(this.current).then(data => {
        this.replaceInListById(this.list, data);

        this.getRef().success(this.$t("cei.updated"));
        this.$refs.dialogUpdate.hide();
      }).finally(() => {
        this.getRef().hideLoader();
      });
    },
    delete() {
      this.getRef().resetMessages();
      ceiService.delete(this.current).then(data => {
        this.removeInListById(this.list, data.id);
        this.getRef().success(this.$t("cei.deleted"))
      }).catch(e => {
        this.getRef().error(e.response.data.error);
      });
    },

    openCreateDialog() {
      this.current = {};
      this.$refs.dialogCreate.show();
    },
    openEditDialog(data) {
      this.current = {...data};
      this.$refs.dialogUpdate.show();
    },
    openDeleteDialog(data) {
      this.current = data;
      this.$refs.dialogDelete.show();
    },
  },
  computed: {
    uerListForCurrent() {
      return this.uerList.filter(uer => uer.agerId === this.current.agerId);
    },
  },
  watch: {
    uerListForCurrent() {
      if (!this.uerListForCurrent.some(uer => uer.id === this.current.uerId))
        this.current.uerId = undefined;
    },
  },
}
</script>

<template v-if="!roleLoading && this.canViewAdmin()">
  <AkFormList :ref="ref" :title="$t('cei.list')">
    <template v-slot:action>
      <div class="action-bar" v-if="!isMobile">
        <span class="p-input-icon-left" style="">
            <i class="pi pi-search"/>
            <InputText v-model="filters['global'].value" :placeholder="$t('search_here')"/>
        </span>
        <button v-if=this.canEditAdmin() class="btn btn-inverse-primary float-right" @click="openCreateDialog()">
          <i class="fe fe-plus pr-1"/>
          {{ $t('add') }}
        </button>
      </div>
    </template>
    <template v-slot:search>
      <div class="row" v-if="isMobile">
        <div class="col-lg-12">
          <div class="card card-statistics mb-30">
            <div class="card-body action-bar flex-start">
              <span class="p-input-icon-left" style="">
                  <i class="pi pi-search"/>
                  <InputText v-model="filters['global'].value" :placeholder="$t('search_here')"/>
              </span>
              <button v-if=this.canEditAdmin() class="btn btn-inverse-primary float-right" @click="openCreateDialog()">
                <i class="fe fe-plus pr-1"/>
                {{ $t('add') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:list>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="table-responsive">
                <DataTable
                    :loading="loading"
                    :paginator="false"
                    :value="list"
                    class="table"
                    :globalFilterFields="['label','agerLabel','uerLabel']"
                    v-model:filters="filters"
                    removableSort
                    sortMode="multiple"
                    :multiSortMeta="multiSortMeta"
                    responsiveLayout="scroll"
                    stripedRows>

                  <template #empty>
                    {{ $t("list_empty") }}
                  </template>

                  <Column field="label" :header="$t('cei.label')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.label }}
                    </template>
                  </Column>
                  <Column field="agerLabel" :header="$t('ager_label')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.agerLabel }}
                    </template>
                  </Column>
                  <Column field="uerLabel" :header="$t('uer_label')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.uerLabel }}
                    </template>
                  </Column>
                  <Column field="uerLabel" :header="$t('type_vh')" :sortable="true">
                    <template #body="slotProps">
                      <i class="fe fe-check" v-if="slotProps.data.displayTypeVh"/>
                    </template>
                  </Column>

                  <Column bodyStyle="text-align: right; overflow: visible"
                          headerStyle=" width: 160px; text-align: center">
                    <template #body="slotProps">
                      <span v-if=this.canEditAdmin() class="btn btn-xs btn-inverse-primary pointer"
                            @click="openEditDialog(slotProps.data)">
                          <i class="fe fe-edit"/></span>
                      <span v-if=this.canDeleteAdmin() class="btn btn-xs btn-inverse-danger ml-1"
                            @click="openDeleteDialog(slotProps.data)">
                          <i class="fe fe-trash"/></span>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:extra>
      <AkDialog ref="dialogCreate"
                :title="$t('cei.create_dialog')"
                :validate-label="$t('add')"
                width="450px"
                @validate="this.create()">
        <div class="form-row">
          <AkInputText :label="$t('cei_label')"
              v-model="current.label"
              :validator="v$.current.label" 
              class-name="col-12"/>

          <AkInputText :label="$t('import_key')"
                       v-model="current.importKey"
                       :validator="v$.current.importKey"
                       class-name="col-12"/>
        </div>
        <div class="form-row">
          <AkDropdown :label="$t('ager_label')"
              v-model="current.agerId"
              :validator="v$.current.agerId" 
              :options=this.agerList
              option-value="id"
              option-label="label"
              class-name="col-12"/>

        </div>
        <div class="form-row">
          <AkDropdown :label="$t('uer_label')"
                      v-if="current.agerId"
                      v-model="current.uerId"
                      :validator="v$.current.uerId"
                      :options=this.uerListForCurrent
                      option-value="id"
                      option-label="label"
                      class-name="col-12"/>
        </div>
        <div class="form-row">
          <AkCheckbox :label="$t('type_vh_display_label')"
                      v-if="current.uerId"
                      v-model="current.displayTypeVh"
                      :validator="v$.current.displayTypeVh"
                      class-name="col-12"/>
        </div>
      </AkDialog>

      <AkDialog ref="dialogUpdate"
                :title="$t('cei.edit_dialog')"
                :validate-label="$t('update')"
                width="450px"
                @validate="this.update()">
        <div class="form-row">
          <AkInputText :label="$t('cei_label')"
              v-model="current.label"
              :validator="v$.current.label" 
              class-name="col-12"/>

          <AkInputText :label="$t('import_key')"
                       v-model="current.importKey"
                       :validator="v$.current.importKey"
                       class-name="col-12"/>
        </div>
        <div class="form-row">
          <AkDropdown :label="$t('ager_label')"
                      v-model="current.agerId"
                      :validator="v$.current.agerId"
                      :options=this.agerList
                      option-value="id"
                      option-label="label"
                      class-name="col-12"/>
        </div>
        <div class="form-row">
          <AkDropdown :label="$t('uer_label')"
                      v-if="current.agerId"
                      v-model="current.uerId"
                      :validator="v$.current.uerId"
                      :options=this.uerListForCurrent
                      option-value="id"
                      option-label="label"
                      class-name="col-12"/>
        </div>
        <div class="form-row">
          <AkCheckbox :label="$t('type_vh_display_label')"
                      v-if="current.uerId"
                      v-model="current.displayTypeVh"
                      :validator="v$.current.displayTypeVh"
                      class-name="col-12"/>
        </div>
      </AkDialog>

      <AkDialog ref="dialogDelete"
                :auto-hide-on-validate="true"
                :cancel-label="$t('no')"
                :title="$t('cei.delete_dialog')"
                :validate-label="$t('yes')"
                width="450px"
                @validate="this.delete()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem"/>
          <span>{{ $t('cei.confirm_delete') }}</span>
        </div>
      </AkDialog>
    </template>
  </AkFormList>
</template>